/* .anim-scroll {
  animation-name: slide;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes slide {
  to {
    transform: translateY(0);
    opacity: 1;
  }
} */

.fade-in-top {
	-webkit-animation: fade-in-top 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite both;
	        animation: fade-in-top 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-3-10 18:22:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
 
 @-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
} 