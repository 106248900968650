@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Code+Latin:wght@200;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Code+Latin:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  font-family: 'M PLUS Code Latin', sans-serif;
  --blue: #021f30;
  --dark-blue: #0b131c;
  --dark-yellow: #fca311;
  --yellow: #f4e409;
  --grey: #aba9a9;
  --light-grey: #e5e5e5;
  --white: #ffffff;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--dark-blue);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
};
